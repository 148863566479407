<template>
  <v-row>
    <v-col>
      <v-card>
        <v-card-title>
          New Agent Form
          <v-btn icon class="ml-2" @click="$refs.agent.reset()">
            <v-icon>mdi-reload</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-avatar color="primary">
            <v-icon dark>mdi-face-agent</v-icon>
          </v-avatar>
        </v-card-title>
        <v-card-text>
          <v-form ref="agent">
            <v-row>
              <v-col>
                <v-text-field
                  label="Name"
                  v-model="form.data.name"
                  :rules="form.rules.name"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="Phone"
                  v-model="form.data.phone"
                  :rules="form.rules.phone"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-textarea
              label="Message"
              v-model="form.message"
              :rules="form.rules.message"
              @input="setLink"
            ></v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            large
            color="primary"
            @click="saveData"
            :loading="form.loading"
            :disabled="form.disable"
          >
            <span>save</span>
            <v-icon right>mdi-content-save</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color" multi-line>
      <v-icon left>
        {{ snackbar.icon }}
      </v-icon>

      {{ snackbar.text }}

      <template v-slot:action>
        <v-btn text @click="snackbar.show = false">
          <span>close</span>
        </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      form: {
        loading: false,
        disable: false,
        message: null,
        data: {
          name: null,
          phone: null,
          link: null,
        },
        rules: {
          name: [
            (v) => !!v || "Name is required",
            (v) =>
              (v && v.length <= 50) || "Name must be less than 50 characters",
          ],
          phone: [(v) => !!v || "Phone is required"],
          message: [(v) => !!v || "Message is required"],
        },
      },
      snackbar: {
        show: false,
        color: null,
        text: null,
        icon: null,
      },
    };
  },
  methods: {
    ...mapActions(["createAgent"]),
    setLink() {
      let message = this.form.message;
      let text = this.encodeMessage(message);
      let phone = this.form.data.phone;
      let url =
        "https://api.whatsapp.com/send?phone=" + phone + "&text=" + text;

      this.form.data.link = url;
      // console.log(url);
    },
    encodeMessage(val) {
      // console.log(escape(val));
      return escape(val);
    },
    saveData() {
      this.form.loading = true;
      this.form.disable = true;
      let data = this.form.data;

      if (this.$refs.agent.validate()) {
        // console.log(data)
        if (this.createAgent(data)) {
          this.form.loading = false;
          this.form.disable = false;
          this.showNotification("success");
        } else {
          this.form.loading = false;
          this.form.disable = false;
          this.showNotification("error");
        }
      } else {
        this.form.loading = false;
        this.form.disable = false;
        this.showNotification("error");
      }
    },
    showNotification(status) {
      switch (status) {
        case "success":
          this.snackbar.show = true;
          this.snackbar.color = "success";
          this.snackbar.text = "Agent added successfully";
          this.snackbar.icon = "mdi-check";
          break;
        case "error":
          this.snackbar.show = true;
          this.snackbar.color = "error";
          this.snackbar.text = "Error adding agent";
          this.snackbar.icon = "mdi-close";
          break;
      }
    },
  },
};
</script>